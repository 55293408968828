import React from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/layout';
import SEO from '../components/seo';
// import LightboxGallery from '../components/LightboxGallery';

import { ProtectedLightboxGallery } from '../components/styles/layout';

const IndexPage = ({ data, location }) => (
	<Layout location={location}>
		<SEO title='Welcome to my portfolio' />
		<ProtectedLightboxGallery data={shuffleArray(data.allWordpressWpIllustrations.edges)} />
	</Layout>
);

const shuffleArray = array => {
	for (let i = array.length - 1; i > 0; i--) {
		const j = Math.floor(Math.random() * (i + 1));
		[array[i], array[j]] = [array[j], array[i]];
	}
	// console.log(array, 'shuffled');

	return array;
};

export default IndexPage;

// Set here the ID of the home page.
export const pageQuery = graphql`
	query {
		allWordpressWpIllustrations {
			edges {
				node {
					id
					date(formatString: "MM dd YYYY")
					content
					title
					type
					illustration_type
					path
					featured_media {
						localFile {
							childImageSharp {
								fluid(maxWidth: 1280) {
									...GatsbyImageSharpFluid_withWebp_tracedSVG
								}
								sizes {
									presentationHeight
									presentationWidth
								}
							}
						}
						alt_text
					}
				}
			}
		}
	}
`;
